import React, { MouseEventHandler } from 'react';
import { useRouter } from 'next/router';
import ParagraphProps from '@src/components/Paragraph/paragraph-props';
import getCmp from '@src/utils/cmp';

const TextParagraph = (props: ParagraphProps) => {
  const router = useRouter();

  const onClickText: MouseEventHandler<HTMLElement> = e => {
    const targetLink = (e.target as HTMLElement).closest('a');
    if (!targetLink) return;

    if (targetLink.className.split(' ').indexOf('cmp-show') !== -1) {
      e.preventDefault();
      getCmp().then(cmp => {
        cmp.showBanner();
      });
      return;
    }

    const { href } = targetLink;
    const url = new URL(href);
    const urlTop = url.hostname.split('.').slice(-2).join('.');
    const locationTop = document.location.hostname.split('.').slice(-2).join('.');
    if (urlTop === locationTop) {
      e.preventDefault();
      router.push('/', url.pathname).catch(reason => {
        console.log('Could not push local pathname', reason, url);
        window.location.href = href;
      });
    }
  };

  return (
    <section
      className="paragraph text-paragraph"
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: props.paragraph.data.html }}
      onClick={onClickText}
    />
  );
};

export default TextParagraph;
